<template lang="pug">
    .main-wrapper.paciente-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "menus" }'> Pesquisa de Satisfação</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } menu`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ds_pergunta.$error }")
                                label.form-label Pergunta:
                                InputText(type='text' v-model='$v.model.ds_pergunta.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_pergunta.$error')
                                    .form-message--error(v-if="!$v.model.ds_pergunta.minLength") <b>Pergunta</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_pergunta.required") <b>Pergunta</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                                label.form-label Tipo de Pergunta:
                                Dropdown(v-model='model.ie_tipo' :options='options.tipo_pergunta' dataKey='value'
                                    optionLabel='text' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo de Pergunta</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_categoria.$error }")
                                label.form-label Categoria:
                                Dropdown(v-model='model.ie_categoria' :options='options.categorias' dataKey='value'
                                    optionLabel='text' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.ie_categoria.$error')
                                    .form-message--error(v-if="!$v.model.ie_categoria.required") <b>Categoria</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_tipo_avaliado.$error }")
                                label.form-label Vínculo:
                                Dropdown(v-model='model.ie_tipo_avaliado' :options='options.vinculo' dataKey='value'
                                    optionLabel='text' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo_avaliado.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo_avaliado.required") <b>Vínculo</b> é obrigatório.
                            
                            .p-col-12.p-md-4
                                label.form-label Ordem:
                                InputText(type='text' v-model='model.nr_ordem')

                            .p-col-12.p-md-4
                                Button(label='Salvar' type="submit")

                
</template>

<style lang="scss">
    .paciente-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'

    import Nps from "../../middleware/controllers/Nps";
    import { required, minLength } from 'vuelidate/lib/validators'
    //import moment from 'moment'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip
        },
        directives: { tooltip: Tooltip },
        created () {

                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                    Nps.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            keys.forEach(key => this.model[key] = response.data[key])
                            this.model.id = id
                        }
                        this.waiting = false
                    })
                } else this.waiting = false
            

        },
        data () {
            return {
                model: {
                    ds_pergunta: '',
                    ie_tipo: '',
                    ie_categoria: '',
                    ie_tipo_avaliado: '',
                    nr_ordem: 0,
                },
                options: {
                    parent: [{ value: null, text: '- Selecione -' }],
                    tipo_pergunta: [
                        { value: null, text: '- Selecione -' },
                        { value: 'N', text: 'Nota' },
                        { value: 'C', text: 'Comentário' },
                    ],
                    categorias: [
                        { value: null, text: '- Selecione -' },
                        { value: 'C', text: 'Consulta' },
                        { value: 'E', text: 'Exame' },
                    ],
                    vinculo: [
                        { value: null, text: '- Selecione -' },
                        { value: 'NA', text: 'Todos' },
                        { value: 'ME', text: 'Médico' },
                        { value: 'ES', text: 'Estabelecimento' },
                    ]
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    ds_pergunta: { required, minLength: minLength(2) },
                    ie_tipo: { required },
                    ie_categoria: { required },
                    ie_tipo_avaliado: { required }
                }
            }
            
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                // if (! dataSend.ds_html_id) dataSend.ds_html_id = dataSend.ds_rota

                this.waitingForm = true
                Nps.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'menus' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else if (([400]).includes(response.status)) {
                        if (response.data)
                            Object.values(response.data).forEach(error => {
                                error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
                            })
                    }
                    this.waitingForm = false
                })
            }
        }
    }
</script>